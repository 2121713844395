import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import graphic1 from "../../images/hmeGraphic1.svg"
import graphic2 from "../../images/hmeGraphic2.svg"
import graphic3 from "../../images/glasboxlogo-w.svg"
import styled from "styled-components"

const Wrapper = styled.section`
  .grid2 {
    grid-template-columns: repeat(2, 1fr);
  }
  @media only screen and (max-width: 576px) {
    .user {
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: 10px;
    }
    .user p {
      font-size: 12px;
    }
    .users h5 {
      font-size: 0.95rem;
    }
    .persona {
      margin-bottom: 0.5rem;
    }
    .areas {
      grid-template-columns: repeat(1, 1fr);
    }
    .screen {
      margin-bottom: 1rem;
    }
  }
`
const SecondPage = () => (
  <Layout>
    <Seo title="Page two" />
    <Wrapper>
      <div className="project-header">
        <StaticImage
          src="../../images/glasbox/hero.jpg"
          alt="Project"
          placeholder="dominantColor"
          className="hero"
        />
        <div className="project-title">
          <div className="client">
            <img className="client-logo" alt="logo" src={graphic3} />
            <p>/ brand development + website design </p>
          </div>
          <div className="desc">
            <h2>BRAND ARCHITECURE</h2>
            <p>
              driving the development of an all-new brand identity and design
              system for architectural window products
            </p>
          </div>
        </div>
      </div>
      <div className="highlights">
        <h5>Project Scope:</h5>
        <ul className="scope">
          <li>Brand Expression</li>
          <li>Design System</li>
          <li>Web, Branded Content + Marketing</li>
        </ul>
      </div>
      <div className="highlights">
        <h5>Overview:</h5>
        <p>
          A new name in luxury window and door products, Glasbox wanted an
          all-new brand identity, messaging framework, branded content
          experiences, product collateral and website. The brand expression and
          visual design system needed to promote a high-end experience that
          reflects the companie’s unique suite of architectural products and
          services.
        </p>
      </div>
      <div className="highlights">
        <h5>Project Goals:</h5>
        <ul>
          <li>
            Develop a universal, scalable design system for Glasbox brand and
            product suite
          </li>
          <li>
            Design all-new identity system, including variations for sub-branded
            products and services
          </li>
          <li>
            Drive messaging framework and how products are alligned across full
            product suite
          </li>
          <li>
            Create collateral templates for Glasbox’s full catalogue of window &
            door products
          </li>
          <li>Launch all new Glasbox website</li>
        </ul>
      </div>
      <div className="highlights">
        <h5>My Role:</h5>
        <ul>
          <li>
            Facilitate discovery sessions with company leaders on project goals
            and requirements
          </li>
          <li>
            Lead the development of brand identity concepts based on executive
            leadership inputs
          </li>
          <li>
            Create scalable brand systems and maketing templates that
            accelerated GTM activities
          </li>
          <li>
            Creative direct photo shoots, video shoots, and marketing
            communications
          </li>
          <li>
            Creative direct photo shoots, video shoots, and marketing
            communications
          </li>
          <li>
            Manage design, marketing and web development vendors in USA, Canada
            and Europe
          </li>
        </ul>
      </div>

      <div className="screens">
        <div className="project-label">
          <p>Brand Design Language + Website (under development] </p>
        </div>
        <StaticImage
          src="../../images/glasbox/glasbox-1.jpg"
          alt="Project"
          placeholder="dominantColor"
          className="screen"
        />
        <StaticImage
          src="../../images/glasbox/glasbox-2.jpg"
          alt="Project"
          placeholder="dominantColor"
          className="screen"
        />
      </div>

      <div className="footer-nav">
        <Link to="/">&#x2190; Recent work</Link>
        <span></span>
        <Link to="/work/hme-ex">Next Project &#8594; </Link>
      </div>
    </Wrapper>
  </Layout>
)

export default SecondPage
